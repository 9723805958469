import React from 'react';
import MainPage from './pages/Main/MainPage';

function App() {
  return (
      <MainPage/>
  );
}

export default App;
